$light-bg: rgb(246, 248, 247);
$light-text: rgb(25, 28, 36);
$light-border: #D1D5DB;
$hover-color: rgb(249, 230, 158);
$muted-color: #9CA3AF;
$divider-color: #E5E7EB;
$footer-bg: #F3F4F6;
$white: white;

body {
  background-color: $light-bg !important;
  color: $light-text !important;

  .navbar-icon {
    color: $light-text !important;
  }

  .ql-editor.ql-blank::before {
    color: grey !important;
  }

  .ql-container {
    background-color: white;
    color: black;
    min-height: 400px;
    border : none !important;
    font-size: large !important;
  }

  .post-title {
    color: $light-text !important;
      &:hover {
        text-decoration: underline !important;
      }
  }

  .navbar-toggler-icon {
    color: white !important;
  }

 .post-title {
    color: $light-text;
  }

  .home-article-border-bottom {
    border-bottom: solid 1px rgba(37, 38, 39, .15) !important;
  }

  .article-content * {
    color: $light-text !important;
  }

  .breadcrumb{
    background-color: $light-bg; // Light mode background variable
    padding: 0.5rem 1rem; // Optional padding
    border-radius: 0.25rem; // Optional rounded corners

  .breadcrumb-item {
    color: $light-text; // Black text color for light mode
    font-weight: bold;

    &:hover {
      color: $hover-color; // Highlight hover effect (e.g., soft red)
    }

    &:active, &.active {
      color: $muted-color; // Muted gray for active items
    }
  }

  .breadcrumb-item::before {
    color: $light-border; // Separator color
  }
  }

  .card {
    border: 1px solid $light-border !important;
  }

  .card-title {
    font-size: medium !important;
    font-weight: bold !important;
  }
  
.navbar {
  border-bottom: 1px solid $light-border; // Subtle border for separation
  padding: 0.5rem 1rem; // Add padding for better spacing
  background-color: white !important;

  .navbar-brand {
    font-size: 1.25rem; // Slightly larger brand text
    font-weight: bold; // Emphasize brand name
    color: $light-text; // Match text color

    &:hover {
      color: $hover-color; // Add hover effect for branding
    }
  }
}

  .carousel-title:hover {
    color: $hover-color !important;
  }

  .footer {
    border-top: 1px solid $light-border;
    a {
      color: $muted-color !important;
    }
    background-color: $footer-bg !important;
    color: black;
  }

  .navbar-nav .nav-link {
    color: $light-text; // Default link color

    &:hover {
      color: $light-text; // Highlight on hover
    }

     &.active {
      color: $light-text !important; /* Color for active link */
    }
  }

}

